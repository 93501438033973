/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { ContentPageLayout } from '@components/Layout';
// @ts-ignore
import featuredImage from '../../resources/images/liinakonkatu.png';
interface IResidentActivityIndexPage {
  data: IMarkdownData;
}

const ResidentActivityIndexPage: FC<IResidentActivityIndexPage> = () => {
  return (
    <ContentPageLayout slug="resident-activity" featuredImageFile={featuredImage} />

  );
};

export default ResidentActivityIndexPage;
